export const getMissingEnvVariables = () => {
    // Should be aligned with variables defined in .env.example
    const envVariables = ['REACT_APP_BACKEND_URI',
        'REACT_APP_AAD_AUTHORITY',
        'REACT_APP_AAD_CLIENT_ID',
        'REACT_APP_AllowedIframeOriginUrl',
        'REACT_APP_DEFAULT_PROGPT',
        'REACT_APP_DEFAULT_ASSISTANTGPT',
        'REACT_APP_IdleTimeOut',
        'REACT_APP_AllowedIframeOriginUrl',
        'REACT_APP_ENABLE_RATING',
        'REACT_APP_OCP_APIM_SUBSCRIPTION_KEY',
        'REACT_APP_MAX_FILE_COUNT_UPLOAD_THREAD_LEVEL',
        'REACT_APP_MAX_FILE_SIZE_THREAD_LEVEL',
        'REACT_APP_MAX_FILE_COUNT_UPLOAD_ASSISTANT_LEVEL',
        'REACT_APP_MAX_FILE_SIZE_UPLOAD_ASSISTANT_LEVEL',
        'REACT_APP_MAX_FILE_COUNT_PER_UPLOAD_PROGPT',
        'REACT_APP_MODELS'
    ];

    const missingVariables = [];

    for (const variable of envVariables) {
        if (!process.env[variable]) {
            missingVariables.push(variable);
        }
    }
    return missingVariables;
};
