import { makeStyles, shorthands } from '@fluentui/react-components';
import {  FC, useEffect, useState } from 'react';
import { ChatWindow } from '../chat/ChatWindow';
import { ChatList } from '../chat/chat-list/ChatList';
import { RegisterGPTWindow } from '../custom-gpt/RegisterGPTWindow';
import { UserManagement } from '../user-management/UserManagement';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ChatRoom } from '../chat/ChatRoom';
import { ExploreGPTWindow } from '../exploregpt/ExploreGPTWindow';
import { ManageGPTWindow } from '../managegpt/ManageGPTWindow';
import { GPTPermission } from '../custom-gpt/GPTPermission';
import { EditGPTWindow } from '../edit-gpt/EditGPTWindow';
import withRoleProtection from '../withRoleProtection';
import { RootState } from '../../redux/app/store';
import { useAppSelector }  from '../../redux/app/hooks';
import { useSelector } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import { UserManagementService } from '../../libs/services/UserManagementService';
import { AuthHelper } from '../../libs/auth/AuthHelper';
import { useUserPermission } from '../../libs/hooks/useUserPermission';
import { IUserPermission } from '../../libs/models/UserPermission';
import Loading from './Loading';

const useClasses = makeStyles({
    container: {
        ...shorthands.overflow('hidden'),
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'start',
        height: '100%',
    },
});

export const ChatView: FC = () => {
    const classes = useClasses();
    let editGPT = useSelector((state: RootState) => state.app.selectedGPT);
    const [ownerDetails, setOwnerDetails] = useState(editGPT?.gptOwnerInfo || JSON.parse(localStorage.getItem('ownerDetails') || '{}'));
    const activeUserInfo = useAppSelector((state: RootState) => state.app.activeUserInfo); 
    const { instance, inProgress } = useMsal();
    const [userRole, setUserRole] = useState('');
    const userPermission = useUserPermission();
    const userManagementService = new UserManagementService(process.env.REACT_APP_BACKEND_URI as string);
    const [hasEditPermission, setHasEditPermission] = useState(false);
    const [loadingRole, setLoadingRole] = useState(true);
    const [loadingPermissions, setLoadingPermissions] = useState(true);
    //const navigate = useNavigate();

    useEffect(() => {
        const fetchUserRole = async () => {
            try{
            const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
            const currentUser = await userManagementService.getUserRoleByEmail(
                activeUserInfo?.email || '',
                accessToken,
            );  
            const role = currentUser?.role?.toString() || '';
            setUserRole(role);
        } catch (error) {
            console.error('Error fetching user role:', error);
        } finally {
            setLoadingRole(false);
        }
        };

        if (activeUserInfo?.email) {
            fetchUserRole();
        }
        else {
            setLoadingRole(false);
        }
    }, [activeUserInfo, instance, inProgress]);

    useEffect(() => {
        const fetchUserPermissions = async () => {
            try {
                const fetchedUserPermissions: any = await userPermission.getPermissionAsync(editGPT?.id || '');
                if (Array.isArray(fetchedUserPermissions)) {
                    const hasPermission = fetchedUserPermissions.some((u: IUserPermission) => u.permission === 1);
                    setHasEditPermission(hasPermission);
                } else {
                    console.error('Fetched User Permissions is not an array:', fetchedUserPermissions);
                    setHasEditPermission(false);
                }
            } catch (error) {
                console.error('Error fetching user permissions:', error);
                setHasEditPermission(false);
            }finally {
                setLoadingPermissions(false);
            }
        };
    
        if (editGPT?.id) {
            fetchUserPermissions();
        }
        else {
            setLoadingPermissions(false);
        }
    }, [editGPT,userPermission]);
    
    useEffect(() => {
        if (editGPT?.gptOwnerInfo) {
            localStorage.setItem('ownerDetails', JSON.stringify(editGPT.gptOwnerInfo));
            setOwnerDetails(editGPT.gptOwnerInfo);
        }
    }, [editGPT]);
    
    const isAdmin = userRole === '0';
    const isOwner = activeUserInfo?.email === ownerDetails?.userPrincipalName;
   
    let EditPermission = false;
    if(activeUserInfo?.roles?.includes('1')) {
        EditPermission = true;
    }
    const canEditGPT = isAdmin || isOwner || hasEditPermission || EditPermission;

    const ProtectedRegisterGPTWindow = withRoleProtection(RegisterGPTWindow, ['0']); 
    const ProtectedUserManagement = withRoleProtection(UserManagement, ['0']); 
    const ProtectedManageGPTWindow = withRoleProtection(ManageGPTWindow, ['0','1']);
    const ProtectedGPTPermission = withRoleProtection(GPTPermission, ['0','1']);
    const ProtectedEditGPTWindow = withRoleProtection(EditGPTWindow, ['0','1']);

    if (loadingRole || loadingPermissions) {
        return <Loading text="Loading..." />
    }

    return (
        <div className={classes.container}>
            <ChatList />
            <Routes>
                <Route path="/register-gpt" element={<ProtectedRegisterGPTWindow activeUserInfo={activeUserInfo} />} />
                <Route path="/user-management" element={<ProtectedUserManagement activeUserInfo={activeUserInfo} />} />
                <Route path="/chat/:chatId" element={<ChatWindow />} />
                <Route path="/documentchat/:chatId" element={<ChatWindow />} />
                {/*  <Route path="/documentchat/:chatId/documents" element={<DocumentsTab />} />*/}
                <Route path="/explore-gpts" element={<ExploreGPTWindow />} />
                <Route path="/manage-gpts" element={<ProtectedManageGPTWindow activeUserInfo={activeUserInfo}/>} />
                <Route path="/" element={<ChatWindow />} />
                <Route path="/explore-gpts/:gptId/chat/:chatId" element={<ChatRoom />} />
                <Route path="/gptPermission/:gptId" element={<ProtectedGPTPermission activeUserInfo={activeUserInfo} />} />
                <Route path="/editGPTWindow/:gptId" element={(() => {return canEditGPT ? 
                <ProtectedEditGPTWindow activeUserInfo={activeUserInfo} ownerDetails={ownerDetails} /> : 
                <Navigate to="/manage-gpts" />;
                })()}/>
            </Routes>
        </div>
    );
};
