import { AuthHelper } from "../auth/AuthHelper";
import { RatingService } from "../services/RatingService";
import { useMsal } from "@azure/msal-react";
import { IRating } from "../models/Rating";
import { toast } from "react-toastify";
import { makeStyles } from "@fluentui/react-components";
import { customFonts } from "../../styles";

const useStyles = makeStyles({

    toastMessage: {
        fontSize: '18px',
        color: 'black',
        fontFamily: customFonts.TitleFont,
        backgroundColor: '#F7F7F8',
        border: "1px solid black",
        marginTop: '33px',
    },
});

export const useSubmitRating = () => {
    const classes = useStyles();
    const { instance, inProgress } = useMsal();
    const ratingService = new RatingService(process.env.REACT_APP_BACKEND_URI as string);
    //const inProgress = useSelector((state: RootState) => state.auth.inProgress);

    const getRating = async (userId: string, gptId: string) => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
        try {
            const rating = await ratingService.getRatingAsync(userId, gptId, accessToken);
            return rating;
        } catch (e: any) {
            const errorMessage = `Unable to get rating. Details: ${getErrorDetails(e)}`;
            toast.error(errorMessage, {
                className: classes.toastMessage,
              });
            return null;
        }
    };

    const updateRating = async (userId: string, gptId: string, rating: number, comment: string) => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
        try {
            const ratingData: IRating = { userId, customGPTId: gptId, value: rating, feedback: comment };
            await ratingService.updateRatingAsync(userId, gptId, ratingData, accessToken);
            toast.success('Rating updated successfully', {
                className: classes.toastMessage,
              });
        } catch (e: any) {
            const errorMessage = `Unable to update rating. Details: ${getErrorDetails(e)}`;
            toast.error(errorMessage, {
                className: classes.toastMessage,
              });
        }
    };

    const submitRating = async (userId: string, customGPTId : string, value: number, feedback: string) => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
        try {
            const ratingData: IRating = { userId, customGPTId, value, feedback }; // Create an IRating object
            await ratingService.submitRatingAsync(ratingData, accessToken);
            toast.success('Rating submitted successfully', {
                className: classes.toastMessage,
              });
        } catch (e: any) {
            const errorMessage = `Unable to submit rating. Details: ${getErrorDetails(e)}`;
            toast.error(errorMessage, {
                className: classes.toastMessage,
              });
        }
    };

    return { submitRating,getRating,updateRating };
    
};
function getErrorDetails(e: any) {
    return e instanceof Error ? e.message : String(e);
}
