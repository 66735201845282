import * as React from 'react';
import {
    makeStyles,
    Image,
    Text,
    Accordion,
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
    Avatar,
    Button,
    Card,
    CardHeader,
    tokens,
    Dialog,
    DialogActions,
    DialogBody,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Divider,
} from '@fluentui/react-components';
import { IGPT } from '../../libs/models/GPT';
import { customColors, customFonts } from '../../styles';
import { IADEmployee } from '../../libs/models/ADEmployee';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { useGPT } from '../../libs/hooks/useGPT';
import { CardBody } from 'react-bootstrap';
import ChatWindowBlockWrapper from '../chat/prompt-suggestion/ChatWindowBlockWrapper';
import { ScrollBarStyles } from '../../styles';

const useClasses = makeStyles({
    container: {
        width: '100%',
        height: '100%',
        margin: '0 auto',
        padding: tokens.spacingVerticalL,
        backgroundColor: tokens.colorNeutralBackground1,
        ...ScrollBarStyles,
    },
    parentCard: {
        padding: tokens.spacingHorizontalM,
        borderRadius: tokens.borderRadiusMedium,
        backgroundColor: tokens.colorNeutralBackground1,
        boxShadow: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        overflow: 'scroll',
    },
    childCard: {
        padding: tokens.spacingHorizontalM,
        borderRadius: tokens.borderRadiusMedium,
        backgroundColor: tokens.colorNeutralBackground1,
        boxShadow: tokens.shadow16,
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'start',
        width: '100%',
        height: '100%',
    },
    childrenCardContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
        width: '100%',
        height: '100%',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: customFonts.TitleFont,
        color: customColors.protivitiDarkBlue,
    },
    logo: {
        width: '80px',
        height: '80px',
        marginRight: '10px',
    },
    headerTitle: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: customFonts.TitleFont,
        color: customColors.protivitiDarkBlue,
    },
    productName: {
        fontSize: tokens.fontSizeHero700,
        fontWeight: tokens.fontWeightBold,
        color: customColors.protivitiDarkBlue,
    },
    description: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '80%',
        textAlign: 'center',
        wordWrap: 'break-word',
        fontSize: tokens.fontSizeBase400,
        marginBottom: tokens.spacingVerticalM,
        color: customColors.protivitiDarkBlue,
    },
    owner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        flexDirection: 'column',
        marginBottom: tokens.spacingVerticalL,
    },
    ownerDetails: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: tokens.spacingVerticalS,
        marginLeft: tokens.spacingHorizontalS,
    },
    ownerName: {
        fontFamily: customFonts.TitleFont,
        color: customColors.protivitiDarkBlue,
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase500,
    },
    ownerEmail: {
        fontSize: tokens.fontSizeBase300,
        color: tokens.colorNeutralForeground3,
    },
    faq: {
        marginTop: tokens.spacingVerticalM,
        borderRadius: tokens.borderRadiusMedium,
        height: 'auto',
        marginBottom: '-20px',
    },
    faqQuestionContainer: {
        padding: tokens.spacingVerticalS + ' ' + tokens.spacingHorizontalM,
        marginLeft: '-10px',
        marginBottom: '-10px',
    },
    faqQuestion: {
        color: customColors.protivitiOrange,
        fontWeight: tokens.fontWeightSemibold,
    },
    faqAnswerContainer: {
        paddingTop: tokens.spacingVerticalS,
        backgroundColor: customColors.lightBlueGrayBackground3,
        borderRadius: tokens.borderRadiusLarge,
        paddingBottom: tokens.spacingVerticalM,
        paddingLeft: tokens.spacingHorizontalM,
        marginTop: '5px',
        width: 'auto',
        overflow: 'auto',
    },
    faqAnswer: {
        color: customColors.protivitiDarkBlue,
        wordWrap: 'break-word',
    },
    dialogSurfaceContainer: {
        width: '100%',
        maxWidth: '30%',
        left: '400px',
        ...ScrollBarStyles,
    },
    modalContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: '20px',
        width: '100%',
    },
    modalCloseButton: {
        fontFamily: customFonts.Lato,
        border: '1px solid #000',
        borderRadius: '0',
        padding: '5px 45px',
        margin: '0 8px 8px 0',
        float: 'right' as 'right',
        color: '#000',
        background: 'transparent',
        fontWeight: '600',
        width: '20%',
        marginLeft: 'auto',
    },

    actions: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: '10%',
    },
});

interface GPTOverviewProps {
    gpt?: IGPT | undefined;
    gptId?: string;
    isModal?: boolean;
    isOpen?: boolean;
    onClose?: () => void;
}

export const GPTOverview: React.FC<GPTOverviewProps> = ({ gpt, gptId, isModal, isOpen, onClose }) => {
    const classes = useClasses();
    const [adEmployeeInfo, setADEmployeeInfo] = React.useState<IADEmployee[] | undefined>();
    const [modalIsOpen, setModalIsOpen] = useState(isOpen);
    const { userGPTs } = useAppSelector((state: RootState) => state.gpts);
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const gptService = useGPT();
    const [_, setValue] = React.useState<string>('');

    const activeGPT = React.useMemo(() => {
        if (!gpt) return userGPTs.find((g) => g.id === gptId);
        return userGPTs.find((g) => g.gptEndpoint === conversations[selectedId]?.gptEndpoint);
    }, [selectedId, userGPTs]);

    const getADEmployeeByUpnAsync = async (upn: string) => {
        const adEmployee = await gptService.getADEmployeeByUpnAsync(upn);
        setADEmployeeInfo(adEmployee);
    };

    const formatFAQAnswer = (answer: string) => {
        return answer.split('\\n').map((prompt, index) => <li key={index}>{prompt}</li>);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        if (onClose) onClose();
    };

    useEffect(() => {
        setModalIsOpen(isOpen);
    }, [isOpen]);

    useEffect(() => {
        if (activeGPT) {
            getADEmployeeByUpnAsync(activeGPT?.gptOwnerInfo?.userPrincipalName ?? '');
        }
    }, []);

    if (!isModal) {
        return (
            <div className={classes.container}>
                <Card className={classes.parentCard}>
                    <CardHeader
                        className={classes.header}
                        header={
                            <Text style={{ fontFamily: customFonts.TitleFont }} size={1000} weight="semibold">
                                {gpt?.name}
                            </Text>
                        }
                        image={<Image src={gpt?.cardImageURL} className={classes.logo} />}
                    />

                    <Text className={classes.description}>{gpt?.description}</Text>

                    <div className={classes.childrenCardContainer}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'start',
                                margin: '0 10px 0 5px',
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            {gpt?.gptCapabilities && (
                                <Card className={classes.childCard} style={{ width: '100%', height: 'auto' }}>
                                    <CardHeader
                                        className={classes.header}
                                        header={
                                            <Text
                                                style={{ fontFamily: customFonts.TitleFont }}
                                                size={800}
                                                weight="semibold"
                                            >
                                                Capabilities
                                                <Divider />
                                            </Text>
                                        }
                                        style={{ marginTop: '-5px' }}
                                    />
                                    {gpt?.gptCapabilities && (
                                        <CardBody
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                maxHeight: '60%',
                                                marginBottom: '3px',
                                            }}
                                        >
                                            <ul>
                                                {gpt?.gptCapabilities.map((capability, index) => (
                                                    <div
                                                        key={index}
                                                        style={{
                                                            color: customColors.protivitiDarkBlue,
                                                            display: 'flex',
                                                            alignItems: 'start',
                                                            justifyContent: 'start',
                                                            marginBottom: '5px',
                                                        }}
                                                    >
                                                        <img
                                                            src={gpt.cardImageURL}
                                                            style={{
                                                                width: '20px',
                                                                height: '20px',
                                                                marginRight: '5px',
                                                            }}
                                                        />
                                                        <Text size={400}>{capability.replace('\\n', '')}</Text>
                                                    </div>
                                                ))}
                                            </ul>
                                        </CardBody>
                                    )}
                                </Card>
                            )}
                            {gpt?.promptLibrary && gpt.promptLibrary.length > 0 && (
                                <Card
                                    className={classes.childCard}
                                    style={{ width: '100%', height: '60%', maxHeight: '70%', marginTop: '5px' }}
                                >
                                    <CardHeader
                                        className={classes.header}
                                        header={
                                            <Text
                                                style={{ fontFamily: customFonts.TitleFont }}
                                                size={800}
                                                weight="semibold"
                                            >
                                                Conversation Starters
                                                <Divider />
                                            </Text>
                                        }
                                        style={{ marginTop: '-5px', marginBottom: '-20px' }}
                                    />
                                    {gpt?.gptCapabilities && (
                                        <CardBody style={{ width: '80%', height: '40%' }}>
                                            <ChatWindowBlockWrapper
                                                promptLibrary={gpt?.promptLibrary}
                                                accessedFromOverview={true}
                                                setValue={setValue}
                                            />
                                        </CardBody>
                                    )}
                                </Card>
                            )}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '40%',
                                height: '100%',
                                justifyContent: 'space-between',
                            }}
                        >
                            {adEmployeeInfo && gpt?.gptOwnerInfo && (
                                <Card
                                    className={classes.childCard}
                                    style={
                                        gpt?.gptKnowledgeBase
                                            ? {
                                                  width: '100%',
                                                  height: 'auto',
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  justifyContent: 'center',
                                              }
                                            : {
                                                  width: '100%',
                                                  height: '100%',
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  justifyContent: 'center',
                                              }
                                    }
                                >
                                    <CardHeader
                                        className={classes.header}
                                        style={{
                                            marginTop: '-5px',
                                        }}
                                        header={
                                            <Text
                                                style={{
                                                    fontFamily: customFonts.TitleFont,
                                                    color: customColors.protivitiDarkBlue,
                                                }}
                                                size={800}
                                                weight="semibold"
                                            >
                                                GPT Owner
                                                <Divider />
                                            </Text>
                                        }
                                    />
                                    <CardBody>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'start',
                                                alignItems: 'start',
                                                height: '100%',
                                            }}
                                        >
                                            <div className={classes.owner}>
                                                <Avatar
                                                    name={
                                                        adEmployeeInfo[0]?.givenName + ' ' + adEmployeeInfo[0]?.surName
                                                    }
                                                    image={{ src: adEmployeeInfo[0]?.photoUrl }}
                                                    shape="square"
                                                    size={128}
                                                />
                                                <div className={classes.ownerDetails}>
                                                    <Text className={classes.ownerName}>
                                                        {adEmployeeInfo[0]?.givenName +
                                                            ' ' +
                                                            adEmployeeInfo[0]?.surName}
                                                    </Text>
                                                    <Text className={classes.ownerEmail}>
                                                        <a
                                                            href={`mailto:${adEmployeeInfo[0].userPrincipalName}?subject=GPT%20Feedback`}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {adEmployeeInfo[0].userPrincipalName}
                                                        </a>
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            fontStyle: 'italic',
                                                            textAlign: 'center',
                                                            marginTop: '20px',
                                                            color: customColors.protivitiDarkBlue,
                                                            fontFamily: customFonts.Lato,
                                                            width: 'auto',
                                                        }}
                                                    >
                                                        {gpt?.gptOwnerBlurb
                                                            ? `"${gpt?.gptOwnerBlurb}"`
                                                            : `"Hope you enjoy using ${gpt?.name}! Please reach out to me if you have any questions or feedback."`}
                                                    </Text>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            )}

                            {gpt?.gptKnowledgeBase && (
                                <Card
                                    className={classes.childCard}
                                    style={{
                                        width: '100%',
                                        height: '50%',
                                        marginTop: '5px',
                                    }}
                                >
                                    <CardHeader
                                        className={classes.header}
                                        header={
                                            <Text
                                                style={{
                                                    fontFamily: customFonts.TitleFont,
                                                    color: customColors.protivitiDarkBlue,
                                                }}
                                                size={800}
                                                weight="semibold"
                                            >
                                                GPT Knowledge Source
                                                <Divider />
                                            </Text>
                                        }
                                        style={{ marginTop: '-5px' }}
                                    />
                                    <CardBody>
                                        <Text
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'start',
                                                alignItems: 'start',
                                                height: '100%',
                                                fontFamily: customFonts.Lato,
                                                color: customColors.protivitiDarkBlue,
                                                fontSize: '12px',
                                                marginLeft: '5px',
                                            }}
                                            block
                                        >
                                            {gpt?.gptKnowledgeBase}
                                        </Text>
                                    </CardBody>
                                </Card>
                            )}
                        </div>
                    </div>
                </Card>
            </div>
        );
    }
    return (
        <Dialog modalType="alert" open={modalIsOpen}>
            <DialogSurface className={classes.dialogSurfaceContainer}>
                <DialogTitle style={{ color: `${customColors.protivitiDarkBlue}`, fontSize: '32px' }}>
                    {<Image src={activeGPT?.cardImageURL} className={classes.logo} />}
                    {activeGPT?.name} FAQs
                </DialogTitle>
                <DialogBody className={classes.modalContainer}>
                    {activeGPT?.faQs ? (
                        activeGPT?.faQs.length > 0 &&
                        activeGPT?.faQs.map((faq, index) => (
                            <Accordion collapsible className={classes.faq}>
                                <AccordionItem value={index.toString()}>
                                    <AccordionHeader className={classes.faqQuestionContainer}>
                                        <Text className={classes.faqQuestion}>{faq.question}</Text>
                                    </AccordionHeader>
                                    <AccordionPanel className={classes.faqAnswerContainer}>
                                        {faq.question.toLowerCase().includes('prompt') ? (
                                            <div className={classes.faqAnswer}>
                                                <ul>{formatFAQAnswer(faq.answer)}</ul>
                                            </div>
                                        ) : (
                                            <Text className={classes.faqAnswer}>{faq.answer}</Text>
                                        )}
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        ))
                    ) : (
                        <Text style={{ color: `${customColors.headerLightGray}` }} size={500}>
                            soon to come...
                        </Text>
                    )}

                    <DialogActions className={classes.actions}>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button onClick={closeModal} className={classes.modalCloseButton}>
                                <Text
                                    style={{
                                        fontFamily: customFonts.TitleFont,
                                        color: customColors.protivitiDarkBlue,
                                    }}
                                    size={500}
                                >
                                    Close
                                </Text>
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};

export default GPTOverview;
