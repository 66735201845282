import { IADEmployee } from '../models/ADEmployee';
import { IUserPermission } from '../models/UserPermission';
import { BaseService } from './BaseService';

export class UserPermissionService extends BaseService {
    public getPermissionsAsync = async (chatId: string, accessToken: string): Promise<IUserPermission> => {
        const result = await this.getResponseAsync<IUserPermission>(
            {
                commandPath: `userpermission/${chatId}`,
                method: 'GET',
            },
            accessToken,
        );
        return result;
    };

    public createPermissionAsync = async (
        chatId: string,
        user: IUserPermission[],
        accessToken: string,
    ): Promise<IUserPermission> => {
        const body = user;
        const result = await this.getResponseAsync<IUserPermission>(
            {
                commandPath: 'userpermission/' + chatId,
                method: 'POST',
                body,
            },
            accessToken,
        );
        return result;
    };

    public addGPTUserPermissionAsync = async (
        userPermission: IUserPermission,
        accessToken: string,
        userId: string,
        userName: string,
    ): Promise<any> => {
        userPermission.userId = userId;
        userPermission.userName = userName;
        const result = await this.getResponseAsync<IUserPermission>(
            {
                commandPath: `userpermission`,
                method: 'POST',
                body: userPermission,
            },
            accessToken,
        );

        return result;
    };

    public updatePermissionAsync = async (user: IUserPermission[], accessToken: string): Promise<IUserPermission> => {
        const body = {
            user,
        };
        const result = await this.getResponseAsync<IUserPermission>(
            {
                commandPath: 'userpermission/update',
                method: 'PUT',
                body,
            },
            accessToken,
        );
        return result;
    };

    public deletePermissionAsync = async (user: IUserPermission[], accessToken: string): Promise<IUserPermission> => {
        const body = {
            user,
        };
        const result = await this.getResponseAsync<IUserPermission>(
            {
                commandPath: `userpermission/delete/${body}`,
                method: 'DELETE',
                body,
            },
            accessToken,
        );
        return result;
    };

    public deleteGPTPermissionsAsync = async (gptId: string, accessToken: string): Promise<any> => {
        const result = await this.getResponseAsync<any>(
            {
                commandPath: `userpermission/deleteAll/${gptId}`,
                method: 'DELETE',
            },
            accessToken,
        );
        return result;
    };

    public getADEmployeeByNameAsync = async (accessToken: string, nameSearch: string): Promise<IADEmployee[]> => {
        const headers = new Headers({
            Authorization: `${accessToken}`,
        });

        headers.append('Content-Type', 'application/json');

        try {
            //const baseURL = 'https://am-microservices-dev01.azure-api.net/';
            //const commandPath = "employeeservices/odata/ADEmployee?$filter=EmployeeType 'Employee' and contains(Name, '" + nameSearch + "')&select=Key,UserPrincipalName,Mail,GivenName,SurName&$orderby=GivenName,SurName";
            //const requestUrl = new URL(commandPath, process.env.API_MANAGEMENT_URL);
            const commandPath = 'userpermission/getadusers?nameSearch=' + nameSearch;
            const requestUrl = new URL(commandPath, process.env.REACT_APP_BACKEND_URI);
            const response = await fetch(requestUrl, {
                method: 'GET',
                headers,
            });

            if (!response.ok) {
                const responseText = await response.text();
                const errorMessage = `${response.status}: ${response.statusText}${
                    responseText ? ` => ${responseText}` : ''
                }`;

                throw Object.assign(new Error(errorMessage));
            }

            const data = await response.json(); // Parse the response as JSON
            return data; // Return the parsed JSON data
        } catch (e: any) {
            let additionalErrorMsg = '';
            if (e instanceof TypeError) {
                // fetch() will reject with a TypeError when a network error is encountered.
                additionalErrorMsg =
                    '\n\nPlease check that your backend is running and that it is accessible by the app';
            }
            throw Object.assign(new Error(`${e as string} ${additionalErrorMsg}`));
        }
    };

    public getADEmployeeByUpnAsync = async (accessToken: string, upn: string): Promise<IADEmployee[]> => {
        const headers = new Headers({
            Authorization: `${accessToken}`,
        });

        headers.append('Content-Type', 'application/json');

        try {
            const commandPath = 'userpermission/getadusersbyupn?upn=' + upn;
            const requestUrl = new URL(commandPath, process.env.REACT_APP_BACKEND_URI);
            const response = await fetch(requestUrl, {
                method: 'GET',
                headers,
            });

            if (!response.ok) {
                const responseText = await response.text();
                const errorMessage = `${response.status}: ${response.statusText}${
                    responseText ? ` => ${responseText}` : ''
                }`;

                throw Object.assign(new Error(errorMessage));
            }

            const data = await response.json(); // Parse the response as JSON
            return data; // Return the parsed JSON data
        } catch (e: any) {
            let additionalErrorMsg = '';
            if (e instanceof TypeError) {
                // fetch() will reject with a TypeError when a network error is encountered.
                additionalErrorMsg =
                    '\n\nPlease check that your backend is running and that it is accessible by the app';
            }
            throw Object.assign(new Error(`${e as string} ${additionalErrorMsg}`));
        }
    };
}
