import { InteractionStatus, PublicClientApplication } from '@azure/msal-browser';
import * as signalR from '@microsoft/signalr';
import { AuthHelper } from '../../../libs/auth/AuthHelper';
import { AlertType } from '../../../libs/models/AlertType';
import { IChatUser } from '../../../libs/models/ChatUser';
import { PlanState } from '../../../libs/models/Plan';
import { addAlert, setReconnectingStatus } from '../app/appSlice';
import { ChatState } from '../conversations/ChatState';
import { AuthorRoles, ChatMessageType, IChatMessage } from './../../../libs/models/ChatMessage';
import { StoreMiddlewareAPI } from './../../app/store';
import { Dictionary } from '@reduxjs/toolkit';


interface HubConnectionInfo {
    connection: signalR.HubConnection;
    endpoint: string;
    hubName: string;
    lastReconnectAttempt?: number;
    reconnectAttempts: number;
}

class SignalRHubConnection {
    //private tokenExp: number | string = 0;
    //private readonly TOKEN_CHECK_INTERVAL = 60000; // Check token every minute
    //private readonly TOKEN_EXPIRY_BUFFER = 300000; // 5 minutes buffer before token expires
    private hubConnections: Map<string, HubConnectionInfo> = new Map();
    private readonly store: StoreMiddlewareAPI;
    private readonly MAX_RECONNECT_ATTEMPTS = 3;
    private readonly RECONNECT_INTERVAL = 3000;
    private readonly isAzureSignalREnabled: boolean = process.env.REACT_APP_AZURE_SIGNALR_ENABLED === 'true';

    // Configurations for different SignalR hubs
    private readonly HUB_CONFIGS = {
        RELAY_HUB_ASSISTANT: {
            name: '/assistantrelayhub',
            matcher: ['assistant', process.env.REACT_APP_ASSISTANT_URI as string, 'localhost:7264'],
        },
        RELAY_HUB_PROGPT: {
            name: '/progptrelayhub',
            matcher: ['progpt', process.env.REACT_APP_PROGPT_URI as string, 'localhost:40443'],
        },
    };

    constructor(store: StoreMiddlewareAPI) {
        this.store = store;
    }

    private static SignalRCallbackMethods = {
        ReceiveMessage: 'ReceiveMessage',
        ReceiveMessageUpdate: 'ReceiveMessageUpdate',
        ReceiveCompletedMessageContent: 'ReceiveCompletedMessageContent',
        UserJoined: 'UserJoined',
        ReceiveUserTypingState: 'ReceiveUserTypingState',
        ReceiveBotResponseStatus: 'ReceiveBotResponseStatus',
        GlobalDocumentUploaded: 'GlobalDocumentUploaded',
        ChatEdited: 'ChatEdited',
        ChatDeleted: 'ChatDeleted',
        ReceiveFollowupMessageUpdate: 'ReceiveFollowupMessageUpdate',
        ReceiveImage: 'ReceiveImage',
        ReceiveAnnotations: 'ReceiveAnnotations',
    };

    private setupSignalRConnectionToChatHub(endpoint: string, hubName: string): signalR.HubConnection {
        const connectionUrl = this.buildHubUrl(endpoint, hubName);
        const msalInstance = new PublicClientApplication(AuthHelper.msalConfig);
        this.isAzureSignalREnabled ? console.warn('Azure SignalR is enabled') : console.warn('Local SignalR is enabled');
        const hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(connectionUrl.toString(), {
                skipNegotiation: this.isAzureSignalREnabled ? false : true,
                transport: signalR.HttpTransportType.WebSockets,
                logger: signalR.LogLevel.Debug,
                withCredentials: true,
                accessTokenFactory: async () => {
                    try {
                        return await AuthHelper.getSKaaSAccessToken(msalInstance, InteractionStatus.None);
                    } catch (error) {
                        console.error('Access token fetch failed:', error);
                        this.handleConnectionError(endpoint, error);
                        throw error;
                    }
                },
            })
            .withAutomaticReconnect(this.createRetryPolicy())
            .configureLogging(signalR.LogLevel.Debug)
            .withHubProtocol(new signalR.JsonHubProtocol())
            .build();
        
        hubConnection.serverTimeoutInMilliseconds = 300000; // 2.5 minutes
        hubConnection.keepAliveIntervalInMilliseconds = 150000; // 5 minutes

        this.registerSignalREvents(hubConnection);
        //this.registerCommonSignalConnectionEvents(hubConnection, endpoint);

        return hubConnection;
    }

    private createRetryPolicy(): number[] {
        return [0, 2000, 5000, 10000, 30000]; // Progressive retry delays
    }

    private buildHubUrl(endpoint: string, hubName: string): URL {
        let baseUrl = process.env.REACT_APP_PROGPT_SIGNALR_URI;
        
        // If no specific hub name is provided, determine based on endpoint
        if (!hubName) {
            hubName = this.getHubNameForEndpoint(endpoint);
        }

        if (endpoint.includes('assistant')) {
            baseUrl = process.env.REACT_APP_ASSISTANT_SIGNALR_URI;
        }
        if (endpoint.includes('localhost:7264')) {
            baseUrl = 'https://localhost:7264';
        }
        if (endpoint.includes('localhost:40443')) {
            baseUrl = 'https://localhost:40443';
        }

        return new URL(hubName, baseUrl);
    }

    private async startSignalRConnection(connectionInfo: HubConnectionInfo): Promise<void> {
        const { connection, endpoint } = connectionInfo;
        
        try {
            if (connection.state === signalR.HubConnectionState.Disconnected) {
                await connection.start();
                console.log(`SignalR Connected to ${endpoint}`);
                connectionInfo.reconnectAttempts = 0;
                this.rejoinGroups(connection);
            }
        } catch (error) {
            console.error(`SignalR Connection Error for ${endpoint}:`, error);
            this.handleConnectionError(endpoint, error);
            
            if (connectionInfo.reconnectAttempts < this.MAX_RECONNECT_ATTEMPTS) {
                connectionInfo.reconnectAttempts++;
                connectionInfo.lastReconnectAttempt = Date.now();
                setTimeout(() => this.startSignalRConnection(connectionInfo), this.RECONNECT_INTERVAL);
            } else {
                this.store.dispatch(addAlert({
                    message: `Failed to connect to ${endpoint} after multiple attempts. Please refresh the page.`,
                    type: AlertType.Error
                }));
            }
        }
    }

    private handleConnectionError(endpoint: string, error: any) {
        const errorMessage = error?.message || String(error);
        console.error(`Connection error for ${endpoint}:`, errorMessage);
        
        if (errorMessage.includes('401')) {
            this.store.dispatch(setReconnectingStatus(true));
            this.store.dispatch(addAlert({
                message: 'Authentication failed. Please refresh the page to re-authenticate.',
                type: AlertType.Error
            }));
        }
    }

    private async rejoinGroups(connection: signalR.HubConnection) {
        const conversations = this.store.getState().conversations.conversations;
        
        try {
            await Promise.all(
                Object.keys(conversations).map(id =>
                    connection.invoke('AddClientToGroupAsync', id)
                )
            );
        } catch (error) {
            console.error('Error rejoining groups:', error);
            this.store.dispatch(addAlert({
                message: 'Failed to rejoin conversation groups. Some messages may be missed.',
                type: AlertType.Warning
            }));
        }
    }

    // private registerCommonSignalConnectionEvents(
    //     hubConnection: signalR.HubConnection,
    //     endpoint: string
    // ) {
    //     // Handle connection closed
    //     hubConnection.onclose((error) => {
    //         if (hubConnection.state === signalR.HubConnectionState.Disconnected) {
    //             const timeUntilExpiry = this.getTimeUntilTokenExpiry();
    //             console.log(`Connection closed. Time until token expiry: ${timeUntilExpiry}ms`);

    //             if (timeUntilExpiry <= this.TOKEN_EXPIRY_BUFFER) {
    //                 // Token is expired or close to expiring
    //                 console.log('Token expired or expiring soon, triggering reconnect');
    //                 this.store.dispatch(setReconnectingStatus(true));
                    
    //                 // Stop the connection and create a new one
    //                 hubConnection.stop()
    //                     .then(() => {
    //                         console.log('Hub connection stopped due to token expiration');
    //                         // Remove old connection and create new one
    //                         this.recreateConnection(endpoint);
    //                     })
    //                     .catch((err) => {
    //                         console.error('Error stopping hub connection:', err);
    //                     });
    //             } else {
    //                 // Connection closed for other reasons
    //                 const errorMessage = error 
    //                     ? `Connection closed due to error: ${error.message}`
    //                     : 'Connection closed unexpectedly';
                    
    //                 this.store.dispatch(addAlert({ 
    //                     message: errorMessage, 
    //                     type: AlertType.Error 
    //                 }));
    //             }
    //         }
    //     });

    //     // Handle reconnecting state
    //     hubConnection.onreconnecting(() => {
    //         if (hubConnection.state === signalR.HubConnectionState.Reconnecting) {
    //             const timeUntilExpiry = this.getTimeUntilTokenExpiry();
    //             console.log(`Reconnecting. Time until token expiry: ${timeUntilExpiry}ms`);

    //             if (timeUntilExpiry <= this.TOKEN_EXPIRY_BUFFER) {
    //                 // Token is expired or close to expiring
    //                 console.log('Token expired during reconnection, creating new connection');
    //                 this.store.dispatch(setReconnectingStatus(true));
                    
    //                 hubConnection.stop()
    //                     .then(() => {
    //                         this.recreateConnection(endpoint);
    //                     })
    //                     .catch((err) => {
    //                         console.error('Error during reconnection stop:', err);
    //                     });
    //             } else {
    //                 // Normal reconnection attempt
    //                 const errorMessage = 'Connection lost. Attempting to reconnect...';
    //                 this.store.dispatch(addAlert({ 
    //                     message: errorMessage, 
    //                     type: AlertType.Warning 
    //                 }));
    //             }
    //         }
    //     });

    //     // Handle successful reconnection
    //     hubConnection.onreconnected(() => {
    //         if (hubConnection.state === signalR.HubConnectionState.Connected) {
    //             console.log('Successfully reconnected');
    //             this.store.dispatch(setReconnectingStatus(false));
                
    //             // Rejoin all conversation groups
    //             this.rejoinGroupsWithRetry(hubConnection);
    //         }
    //     });

    //     // Set up token expiration check on connection
    //     hubConnection.on('connected', () => {
    //         this.updateTokenExpiration();
    //         this.startTokenExpirationCheck(hubConnection, endpoint);
    //     });
    // }

    // New method to handle token expiration checks
    // private startTokenExpirationCheck(hubConnection: signalR.HubConnection, endpoint: string) {
    //     const checkInterval = setInterval(() => {
    //         console.warn('Checking for token expiration...');
    //         // Only proceed if the connection is still active
    //         if (hubConnection.state === signalR.HubConnectionState.Connected) {
    //             const timeUntilExpiry = this.getTimeUntilTokenExpiry();
                
    //             if (timeUntilExpiry <= this.TOKEN_EXPIRY_BUFFER) {
    //                 console.warn('Token expiring soon, initiating connection refresh');
                    
    //                 // Clear the check interval before recreating the connection
    //                 clearInterval(checkInterval);
                    
    //                 // Stop the current connection and create a new one
    //                 hubConnection.stop()
    //                     .then(() => {
    //                         this.recreateConnection(endpoint);
    //                     })
    //                     .catch((error) => {
    //                         console.error('Error stopping connection during token refresh:', error);
    //                         // Still try to recreate connection even if stop fails
    //                         this.recreateConnection(endpoint);
    //                     });
    //             }
    //         } else {
    //             // Connection is no longer active, clear the interval
    //             clearInterval(checkInterval);
    //         }
    //     }, this.TOKEN_CHECK_INTERVAL);

    //     // Store the interval ID in case we need to clean it up later
    //     return checkInterval;
    // }

    // Helper method to update token expiration time
    // private updateTokenExpiration() {
    //     const tokenData = this.store.getState().commonData.tokenData;
    //     this.tokenExp = tokenData?.tokenExpiry || 0;
    //     console.log('Token expiration updated:', this.tokenExp);
    // }

    // Helper method to calculate time until token expires
    // private getTimeUntilTokenExpiry(): number {
    //     return (this.tokenExp as number * 1000) - Date.now();
    // }

    // Helper method to recreate a connection
    // private async recreateConnection(endpoint: string) {
    //     const connectionKey = `${endpoint}-${this.getHubNameForEndpoint(endpoint)}`;
    //     const oldConnection = this.hubConnections.get(connectionKey);
        
    //     if (oldConnection) {
    //         try {
    //             // Stop the old connection
    //             await oldConnection.connection.stop();
    //             console.log('Old connection stopped');

    //             // Create new connection
    //             const newConnection = this.setupSignalRConnectionToChatHub(endpoint, oldConnection.hubName);
                
    //             // Update the connection info
    //             const connectionInfo: HubConnectionInfo = {
    //                 connection: newConnection,
    //                 endpoint,
    //                 hubName: oldConnection.hubName,
    //                 reconnectAttempts: 0
    //             };

    //             this.hubConnections.set(connectionKey, connectionInfo);
    //             await this.startSignalRConnection(connectionInfo);
                
    //             console.log('New connection established');
    //         } catch (error) {
    //             console.error('Error recreating connection:', error);
    //             this.store.dispatch(addAlert({
    //                 message: 'Failed to refresh connection. Please reload the page.',
    //                 type: AlertType.Error
    //             }));
    //         }
    //     }
    // }

    // Enhanced group rejoining with retry logic
    private async rejoinGroupsWithRetry(hubConnection: signalR.HubConnection, attempts: number = 0) {
        const MAX_REJOIN_ATTEMPTS = 3;
        const REJOIN_DELAY = 2000; // 2 seconds between attempts

        try {
            const conversations = this.store.getState().conversations.conversations;
            await Promise.all(
                Object.keys(conversations).map(id =>
                    hubConnection.invoke('AddClientToGroupAsync', id)
                )
            );
            console.log('Successfully rejoined all groups');
        } catch (error) {
            console.error(`Failed to rejoin groups, attempt ${attempts + 1}:`, error);
            
            if (attempts < MAX_REJOIN_ATTEMPTS) {
                setTimeout(() => {
                    this.rejoinGroupsWithRetry(hubConnection, attempts + 1);
                }, REJOIN_DELAY);
            } else {
                this.store.dispatch(addAlert({
                    message: 'Failed to rejoin conversation groups. Some messages may be missed.',
                    type: AlertType.Warning
                }));
            }
        }
    }

    // Helper to get hub name based on endpoint
    private getHubNameForEndpoint(endpoint: string): string {
        const config = Object.values(this.HUB_CONFIGS).find(cfg => 
            cfg.matcher.some(match => endpoint.includes(match))
        ) || this.HUB_CONFIGS.RELAY_HUB_PROGPT;
        
        return config.name;
    }

     private registerSignalREvents(hubConnection: signalR.HubConnection) {
        hubConnection.on(
            SignalRHubConnection.SignalRCallbackMethods.ReceiveMessage,
            (chatId: string, senderId: string, message: IChatMessage) => {
                if (message.authorRole === AuthorRoles.Bot) {
                    const loggedInUserId = this.store.getState().app.activeUserInfo?.id;
                    const responseToLoggedInUser = loggedInUserId === senderId;
                    message.planState =
                        message.type === ChatMessageType.Plan && responseToLoggedInUser
                            ? PlanState.PlanApprovalRequired
                            : PlanState.Disabled;

                    this.store.dispatch({
                        type: 'currentmessages/setGeneratingChatMessage',
                        payload: { chatId: chatId, message: message },
                    });

                    this.store.dispatch({
                        type: 'conversations/addMessageToConversationFromServer',
                        payload: { chatId: chatId, message: message },
                    });
                }
            },
        );

        hubConnection.on(
            SignalRHubConnection.SignalRCallbackMethods.ReceiveFollowupMessageUpdate,
            (chatId: string, followupQuestion: string[]) => {
                this.store.dispatch({
                    type: 'followupquestions/setFollowupquestions',
                    payload: { chatId, followupQuestion },
                });
            },
        );

        hubConnection.on(SignalRHubConnection.SignalRCallbackMethods.ReceiveMessageUpdate, (message: IChatMessage) => {
            const { chatId, id: messageId, citations } = message;
            if (message.tokenUsage) {
                this.store.dispatch({
                    type: 'conversations/updateMessageProperty',
                    payload: {
                        chatId,
                        messageIdOrIndex: messageId,
                        property: 'tokenUsage',
                        value: message.tokenUsage,
                        citationsArray: citations,
                        frontLoad: true,
                    },
                });
            } else {
                this.store.dispatch({
                    type: 'currentmessages/updateGeneratingMessageContent',
                    payload: {
                        chatId: chatId,
                        content: message.content,
                    },
                });
            }
        });

        hubConnection.on(SignalRHubConnection.SignalRCallbackMethods.ReceiveCompletedMessageContent, (chatId: string, message: IChatMessage) => {
            this.store.dispatch({
                type: 'currentmessages/updateIsResponseGenerating',
                payload: {
                    chatId: chatId,
                    isResponseGenerating: false,
                },
            });

            this.store.dispatch({
                type: 'conversations/updateMessageProperty',
                payload: {
                    chatId: chatId,
                    messageIdOrIndex: message.id,
                    property: 'content',
                    value: message.content,
                    citationsArray: message.citations,
                    frontLoad: true,
                },
            });

            this.store.dispatch({
                type: 'currentmessages/removeGeneratingMessage',
                payload: {
                    chatId: chatId,
                },
            });
        });

        hubConnection.on(SignalRHubConnection.SignalRCallbackMethods.UserJoined, (chatId: string, userId: string) => {
            const user: IChatUser = {
                id: userId,
                online: false,
                fullName: '',
                emailAddress: '',
                isTyping: false,
                photo: '',
            };
            this.store.dispatch({ type: 'conversations/addUserToConversation', payload: { user, chatId } });
        });

        hubConnection.on(
            SignalRHubConnection.SignalRCallbackMethods.ReceiveUserTypingState,
            (chatId: string, userId: string, isTyping: boolean) => {
                this.store.dispatch({
                    type: 'conversations/updateUserIsTypingFromServer',
                    payload: { chatId, userId, isTyping },
                });
            },
        );

        hubConnection.on(SignalRHubConnection.SignalRCallbackMethods.ReceiveBotResponseStatus, (chatId: string, status: string | undefined) => {
            this.store.dispatch({
                type: 'currentmessages/updateBotResponseStatus',
                payload: {
                    chatId: chatId,
                    status: status ?? '',
                },
            });
        });

        hubConnection.on(SignalRHubConnection.SignalRCallbackMethods.GlobalDocumentUploaded, (fileNames: string, userName: string) => {
            this.store.dispatch(addAlert({ message: `${userName} uploaded ${fileNames} to all chats`, type: AlertType.Info }));
        });

        hubConnection.on(SignalRHubConnection.SignalRCallbackMethods.ChatEdited, (chat: ChatState, chatInfo?: Dictionary<string>) => {
            const id = chat ? chat.id : chatInfo?.id;
            let title = chat ? chat.title : chatInfo?.title;
            if (chat && id && !(id in this.store.getState().conversations.conversations)) {
                this.store.dispatch(
                    addAlert({
                        message: `Chat ${id} not found in store. Chat edited signal from server is not processed.`,
                        type: AlertType.Error,
                    }),
                );
            }
            this.store.dispatch({ type: 'conversations/editConversationTitle', payload: { id: id, newTitle: title } });
        });

        hubConnection.on(SignalRHubConnection.SignalRCallbackMethods.ReceiveImage, (base64String: string, message: IChatMessage) => {
            const { chatId, id: messageId } = message;
            this.store.dispatch({
                type: 'conversations/updateMessageWithImage',
                payload: {
                    chatId,
                    messageIdOrIndex: messageId,
                    image: base64String,
                },
            });
        });

        hubConnection.on(SignalRHubConnection.SignalRCallbackMethods.ReceiveAnnotations, (message: IChatMessage) => {
            const { chatId, id: messageId, annotations, citations } = message;
            this.store.dispatch({
                type: 'conversations/updateMessageWithAnnotations',
                payload: {
                    chatId,
                    messageIdOrIndex: messageId,
                    annotations: annotations,
                    frontLoad: true,
                },
            });

            if (citations) {
                this.store.dispatch({
                    type: 'conversations/updateMessageProperty',
                    payload: {
                        chatId,
                        messageIdOrIndex: messageId,
                        property: 'citationsArray',
                        value: citations,
                        frontLoad: true,
                    },
                });
            }
        });

        hubConnection.on(SignalRHubConnection.SignalRCallbackMethods.ChatDeleted, (chatId: any) => {
            chatId = chatId.toString();
            const isSignal = true;
            this.store.dispatch({
                type: 'conversations/deleteConversation',
                payload: {
                    chatId,
                    isSignal,
                },
            });
        });
    }

    public getOrCreateHubConnection(endpoint: string, hubName?: string): signalR.HubConnection {
        const connectionKey = `${endpoint}-${hubName || 'default'}`;
        
        if (!this.hubConnections.has(connectionKey)) {
            const connection = this.setupSignalRConnectionToChatHub(endpoint, hubName || '');
            const connectionInfo: HubConnectionInfo = {
                connection,
                endpoint,
                hubName: hubName || 'default',
                reconnectAttempts: 0
            };
            
            this.hubConnections.set(connectionKey, connectionInfo);
            this.startSignalRConnection(connectionInfo);
        }
        
        return this.hubConnections.get(connectionKey)!.connection;
    }

     public getAllConnections(): Map<string, HubConnectionInfo> {
        return this.hubConnections;
    }
}

export default SignalRHubConnection;
