import { IUser } from '../models/User';
import { BaseService } from './BaseService';



export class UserManagementService extends BaseService {
  public getUsersAsync = async (accessToken: string, pageNumber: number, pageSize: number,searchTerm: string): Promise<PaginatedResponse<IUser>> => {
    return await this.getResponseAsync<PaginatedResponse<IUser>>(
      {
        commandPath: `user/getAllUsers?pageNumber=${pageNumber}&pageSize=${pageSize}&searchTerm=${searchTerm}`,
        method: 'GET',
      },
      accessToken,
    );
  };

  public getUserRoleByEmail = async (email: string, accessToken: string): Promise<IUser> => {
    return await this.getResponseAsync<IUser>(
      {
        commandPath: `user/getUserRoleByEmail?email=${email}`,
        method: 'GET',
      },
      accessToken,
    );
  };

  public updateUserAsync = async (
    userId: string,
    data: IUser,
    accessToken: string,
  ): Promise<IUser> => {
    return await this.getResponseAsync<IUser>(
      {
        commandPath: `user/${userId}`,
        method: 'PATCH',
        body: data,
      },
      accessToken,
    );
  }
}
