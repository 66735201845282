import { Button, makeStyles } from '@fluentui/react-components';
import React, { useCallback, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import DOMPurify from 'dompurify';
import { IChatMessage, AuthorRoles, ChatMessageType } from '../../../libs/models/ChatMessage';
import { convertToAnchorTags } from '../../utils/TextUtils';
import { OutputTable } from './output-components/OutputTable';
import { OutputPreBlock } from './output-components/OutputPreBlock';
import { OutputCodeBlock } from './output-components/OutputCodeBlock';
import * as utils from './../../utils/TextUtils';
import * as fileUtils from './../../utils/FileUtils';
import ImageScaleButton from '../controls/ImageScaleButton';
import { useChat, useFile } from '../../../libs/hooks';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { ScrollBarStyles } from '../../../styles';
import { IAskVariables } from '../../../libs/semantic-kernel/model/Ask';
import { GetResponseOptions } from '../../../libs/hooks/useChat';
import {
    addMessageToConversationFromUser,
    setConversation,
} from '../../../redux/features/conversations/conversationsSlice';

export const useClasses = makeStyles({
    userContent: {
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
        opacity: 0,
        transition: 'opacity 0.3s ease-in',
    },
    botContent: {
        wordBreak: 'break-word',
        opacity: 0,
        transition: 'opacity 0.3s ease-in',
    },
    imageContainer: {
        position: 'relative',
        display: 'inline-block',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px',
        gap: '10px',
        opacity: 0,
        transition: 'opacity 0.3s ease-in',
        objectFit: 'contain',
    },
    imageContainerVisible: {
        opacity: 1,
    },
    messageImage: {
        maxWidth: '100%',
        maxHeight: '400px',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.4)',
        objectFit: 'contain',
    },
    modalOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    modalContentContainer: {
        position: 'relative',
        maxWidth: '90%',
        maxHeight: '90%',
        display: 'inline-block',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '15px',
        gap: '12px',
        borderRadius: '8px',
        ...ScrollBarStyles,
    },
    modalImage: {
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'scale-down',
        borderRadius: '10px',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.4)',
    },
    visible: {
        opacity: 1,
    },
});

interface ChatHistoryTextContentProps {
    message: IChatMessage;
}
interface FileContentDownloadResponse {
    fileContent: string;
    contentType: string;
}

export const ChatHistoryTextContent: React.FC<ChatHistoryTextContentProps> = ({ message }) => {
    const classes = useClasses();
    const chat = useChat();
    const dispatch = useAppDispatch();
    const { downloadFileContent } = useFile();
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [isTextVisible, setIsTextVisible] = useState(false);
    const toggleModal = () => setIsModalOpen(!isModalOpen);
    const isBot = message.authorRole === AuthorRoles.Bot;
    const [isRetryDisabled, setIsRetryDisabled] = useState(true);
    const [countdown, setCountdown] = useState(0);
    const [failedUserMessageId, setFailedUserMessageId] = useState('');

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    let content = isBot
        ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          utils
              .formatBotChatTextContent(message.content)
              .replace(/([^`\n])\`\`\`/g, '$1\n```')
              .replace(/``` /g, '``` \n')
        : utils
              .formatChatTextContent(message.content)
              .trim()
              .replace(/[\u00A0-\u9999<>&]/g, function (i: string) {
                  return `&#${i.charCodeAt(0)};`;
              });
    // Sanitize the HTML string with DOMPurify
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument
    const sanitizedHTML = DOMPurify.sanitize(convertToAnchorTags(content));

    // Strict usage of the following components within the ChatHistoryTextContent component only
    const ImageDisplayComponent = ({ imageBase64, className }: { imageBase64: string; className: string }) => (
        <img
            style={{ objectFit: 'contain', width: '100%', height: '100%' }}
            src={`data:image/png;base64,${imageBase64}`}
            className={className}
        />
    );

    const ModalComponent = ({
        isOpen,
        toggleModal,
        children,
    }: {
        isOpen: boolean;
        toggleModal: () => void;
        children: React.ReactNode;
    }) => {
        if (!isOpen) return null;
        return (
            <div className={classes.modalOverlay}>
                <div className={classes.modalContentContainer} onClick={(e) => e.stopPropagation()}>
                    <div style={{ position: 'absolute', top: '8px', right: '5px', objectFit: 'contain' }}>
                        <ImageScaleButton iconType="minimize" onClick={toggleModal} />
                    </div>
                    {children}
                </div>
            </div>
        );
    };

    const getLastUserMessage = (messages: IChatMessage[]): IChatMessage | null => {
        for (let i = messages.length - 1; i >= 0; i--) {
            if (messages[i].authorRole === AuthorRoles.User) {
                return messages[i];
            }
        }
        return null;
    };
    const getLastBotMessageIndex = (messages: IChatMessage[]): number | null => {
        for (let i = messages.length - 1; i >= 0; i--) {
            if (messages[i].authorRole === AuthorRoles.Bot) {
                return i;
            }
        }
        return null;
    };

    const getLastUserMessageIndex = (messages: IChatMessage[]): number | null => {
        for (let i = messages.length - 1; i >= 0; i--) {
            if (messages[i].authorRole === AuthorRoles.User) {
                return i;
            }
        }
        return null;
    };
    const lastBotMessageIndex = getLastBotMessageIndex(conversations[selectedId].messages);
    const lastUserMessageIndex = getLastUserMessageIndex(conversations[selectedId].messages);

    const handleResubmit = async () => {
        const chatInput: IChatMessage = {
            chatId: message.chatId,
            timestamp: new Date().getTime(),
            userId: getLastUserMessage(conversations[selectedId].messages)!.userId,
            userName: getLastUserMessage(conversations[selectedId].messages)!.userName,
            content: getLastUserMessage(conversations[selectedId].messages)!.content,
            type: ChatMessageType.Message,
            authorRole: AuthorRoles.User,
        };
        const _contextVariables: IAskVariables[] = [];
        const options: GetResponseOptions = {
            messageType: ChatMessageType.Message,
            value: getLastUserMessage(conversations[selectedId].messages)!.content,
            chatId: message.chatId,
            contextVariables: _contextVariables,
            isDocumentChat: true,
            regenerate: false,
            gptEndpoint: conversations[selectedId].gptEndpoint,
        };
        await chat.deleteChatMessageFromAssistant(
            message.chatId,
            failedUserMessageId,
            conversations[selectedId].gptEndpoint,
        );

        if (lastBotMessageIndex !== null && lastBotMessageIndex > 0) {
            const newMessages = [
                ...conversations[selectedId].messages.slice(0, lastUserMessageIndex! - 1),
                ...conversations[selectedId].messages.slice(lastBotMessageIndex + 1),
            ];
            dispatch(
                setConversation({
                    ...conversations[selectedId],
                    messages: newMessages,
                    id: selectedId,
                }),
            );
        }
        dispatch(addMessageToConversationFromUser({ message: chatInput, chatId: message.chatId }));
        await chat.getResponse(options);
    };

    const onDownloadAnnotationClick = useCallback(() => {
        const { fileName, fileId } = fileUtils.getAnnotationVariablesFromMessage(message);
        chat.downloadFileContent(fileId, conversations[selectedId].gptEndpoint)
            .then(async (response) => {
                if (response) {
                    const { fileContent, contentType } = response as FileContentDownloadResponse;
                    const mimeTypeToFileExtension: { [key: string]: string } = {
                        'text/csv': 'csv',
                        'application/pdf': 'pdf',
                        'application/msword': 'doc',
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
                        'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
                        'image/jpeg': 'jpg',
                        'image/png': 'png',
                    };

                    let fileExtension = mimeTypeToFileExtension[contentType] || 'bin';
                    downloadFileContent(
                        `${fileName}-${new Date().toISOString()}.${fileExtension}`,
                        fileUtils.base64ToArrayBuffer(fileContent),
                        contentType || 'application/octet-stream',
                    );
                }
            })
            .catch((error) => {
                console.error('Error downloading file content:', error);
            });
    }, [chat, conversations, selectedId, downloadFileContent]);

    const [textContent, setTextContent] = useState(content);

    useEffect(() => {
        // Adjusted regex to match any number and any string before and after †
        const regex = /【\d+(:\d+)?†[^】]+】/g;
        const newText = content.replace(regex, '');
        setTextContent(newText);
    }, [content]);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsTextVisible(true);
        }, 1);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        let timer: NodeJS.Timeout;
        const match = textContent.match(/Rate limit exceeded for (msg_[\w\d]+)\. Please try again in (\d+) seconds\./);
        if (match) {
            setIsRetryDisabled(true);
            let retrySeconds = parseInt(match[2], 10);
            setFailedUserMessageId(match[1]);
            setCountdown(retrySeconds);

            let timer = setInterval(() => {
                retrySeconds -= 1;
                setCountdown(retrySeconds);

                if (retrySeconds <= 0) {
                    clearInterval(timer);
                    setIsRetryDisabled(false);
                }
            }, 1000);
        }
        return () => {
            if (timer) clearInterval(timer);
        };
    }, [textContent]);

    const rateLimitExceededContent = textContent.includes('Rate limit exceeded') ? (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                <path
                    fill="#cf2020"
                    d="M16.408 4.172c-1.045-1.896-3.77-1.896-4.815 0L2.35 20.92c-1.011 1.833.314 4.078 2.408 4.078H23.24c2.093 0 3.42-2.245 2.408-4.078zM15 20a1 1 0 1 1-2 0a1 1 0 0 1 2 0m-1.75-3.25v-6.5a.75.75 0 0 1 1.5 0v6.5a.75.75 0 0 1-1.5 0"
                />
            </svg>
            {textContent}
        </div>
    ) : (
        textContent
    );

    const isJsxElement = (element: any): element is JSX.Element => {
        return !!element && typeof element === 'object' && 'props' in element;
    };

    return (
        <div
            className={
                isBot
                    ? `${classes.botContent} ${isTextVisible ? classes.visible : ''}`
                    : `${classes.userContent} ${isTextVisible ? classes.visible : ''}`
            }
        >
            {isBot ? (
                <>
                    {isJsxElement(rateLimitExceededContent) ? (
                        rateLimitExceededContent
                    ) : (
                        <ReactMarkdown
                            remarkPlugins={[[remarkGfm, { tablePipeAlign: true, tableCellPadding: true }]]}
                            components={{
                                table: ({ ...props }) => <OutputTable {...props} />,
                                pre: ({ ...props }) => <OutputPreBlock {...props} />,
                                code: ({ ...props }) => <OutputCodeBlock {...props} />,
                                a: ({ href, children, ...props }) => (
                                    <a
                                        href={href}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={(e) => {
                                            if (href === '#' || !href) {
                                                e.preventDefault();
                                                onDownloadAnnotationClick();
                                            }
                                        }}
                                        {...props}
                                    >
                                        {children}
                                    </a>
                                ),
                            }}
                        >
                            {textContent}
                        </ReactMarkdown>
                    )}
                </>
            ) : (
                <div
                    className={`${classes.userContent} ${isTextVisible ? classes.visible : ''}`}
                    dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
                />
            )}
            {message.images && message.images.length > 0 && (
                <div className={`${classes.imageContainer} ${isVisible ? classes.imageContainerVisible : ''}`}>
                    <ImageDisplayComponent imageBase64={message.images!} className={classes.messageImage} />
                    <div style={{ position: 'absolute', top: 0, right: 0 }}>
                        <ImageScaleButton iconType="maximize" onClick={toggleModal} />
                    </div>
                </div>
            )}
            <ModalComponent isOpen={isModalOpen} toggleModal={toggleModal}>
                <ImageDisplayComponent imageBase64={message.images!} className={classes.modalImage} />
            </ModalComponent>
            {textContent.includes('Rate limit exceeded') ? (
                <Button onClick={handleResubmit} disabled={isRetryDisabled}>
                    {isRetryDisabled ? `Resubmit last prompt in ${countdown} seconds...` : 'Resubmit'}
                </Button>
            ) : (
                ''
            )}
        </div>
    );
};
